export enum ESanityWidget {
  PRODUCTS = 'products',
  ARTICLES = 'articles',
  BLOG_ARTICLE = 'blogArticle',
  CONTAINER = 'container',
  CAROUSEL_TRUSTPILOT = 'trustpilot',
  GRID = 'grid',
  FAQ = 'faq',
  CHARTS = 'chartsComponent',
  CHARTS_INVESTMENT = 'chartsInvestmentComponent',
  SECURE_SAVING = 'secureSavings',
  IMAGE = 'imageComponent',
  EXTERNAL_IMAGE = 'externalImage',
  INVESTMENT_ASSISTANT = 'investmentAssistant',
  DISCOUNT_BANNER = 'discountBanner',
  CAROUSEL_BANNER = 'carouselBanner',
  CAROUSEL_PRODUCT = 'carouselProducts',
  CAROUSEL_SUBCATEGORIES = 'carouselSubcategories',
  CAROUSEL_SUBCATEGORIES_BANNER = 'carouselSubcategoriesBanner',
  RATING_STARS = 'ratingStars',
  PAGE_RATING = 'pageRating',
  TABLE_SPOT_PRICE_HISTORY = 'spotPriceHistory',
  TABLE_SPOT_PRICE_DAILY_STAT = 'spotPriceDailyStats',
  TEXT_EDITOR = 'textEditor',
  TEXT = 'textWidget',
  BUTTON_GROUP = 'buttonGroup',
  RICH_TEXT = 'richText',
  MARGIN = 'margin',
  GUIDE_BLOCK = 'guideBlock',
  CHART_TRADING = 'chartTrading',
  INVESTMENT_ASSISTANT_BANNER = 'investmentAssistantBanner',
  VIDEO = 'videoComponent',
  TWEET = 'tweet',
  PRODUCT_TABS = 'productTabs',
}

export type SanityWidget = {
  _type: ESanityWidget;
  _key: string;
  apiParams: Record<string, string>;
};

export type SanityBaseWidget = {
  _type: ESanityWidget;
  _key: string;
  title: string;
};
