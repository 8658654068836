import { ImageBlock } from '@web-app/view/home/components/ImageBlock';
import { useEffect, useRef } from 'react';

import { Box, Link, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { metalToSlug } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EFontWeight, ERouting, ETypographyVariant } from '@core/type';

import { SlideSubcategoryProps } from './interface-slider-subcategories';

export const SlideBanner = ({
  slug,
  index,
  metalIso,
  title,
  image,
  setSlidesWidth,
}: SlideSubcategoryProps) => {
  const { mediaQuery } = useContextMediaQuery();
  const { language } = useLanguage();
  const { isMobile } = useContextMediaQuery();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      const { width } = ref.current.getBoundingClientRect();

      setSlidesWidth(index, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, mediaQuery]);

  return (
    <Box ref={ref}>
      <LinkTranslatedStyledAsLink
        keyRoute={ERouting.SHOP_SUBCATEGORY}
        query={{ metal: metalToSlug[language][metalIso], slug }}
        hasFullPath
      >
        <Link>
          <Box
            position={'relative'}
            width={isMobile ? '210px' : '400px'}
            height={isMobile ? '190px' : '300px'}
            borderRadius={'8px'}
            overflow={'hidden'}
          >
            {image?.asset && (
              <ImageBlock
                alt={title}
                style={{ objectFit: 'cover', height: '100%' }}
                {...image.asset}
              />
            )}
            <Box
              padding={isMobile ? '4px 16px' : '4px 24px'}
              position={'absolute'}
              left={isMobile ? '16px' : '24px'}
              bottom={isMobile ? '16px' : '24px'}
              borderRadius={'25px'}
              maxWidth={isMobile ? 'calc(100% - 32px)' : 'calc(100% - 48px)'}
              backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            >
              <Typography
                variant={isMobile ? ETypographyVariant.BODY3 : ETypographyVariant.BODY1}
                fontWeight={EFontWeight.MEDIUM}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Link>
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};
