import useTranslation from 'next-translate/useTranslation';

import { Box, ContainerCarouselViews, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EPosition, ETypographyVariant } from '@core/type';
import {
  SanitySliderSubcategoriesBanner,
  SanitySubcategoryLinkObjProps,
  SanityWidget,
} from '@core/type/sanity';
import { ButtonArrow } from '@core/widget';

import { SlideBanner } from './SlideBanner';
import { useCarouselSubcategories } from './use-carousel-subcategories';

const SLIDES_GAP = 16;

export const SliderSubcategoriesBanner = ({
  subcategories: initialSubcategories,
}: {
  subcategories: SanitySubcategoryLinkObjProps[];
}) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  const subcategories = initialSubcategories.filter((value) => value?.carouselBanner);
  const { ref, scrollLeft, scrollWidth, setSlidesWidth, handleNext, handlePrevious } =
    useCarouselSubcategories(subcategories, SLIDES_GAP);

  return (
    <Box>
      <Typography as={EActionAttribute.H2} variant={ETypographyVariant.H1} marginBottom={'24px'}>
        {tWidget('subcategoryBanner.title')}
      </Typography>
      <Box position={'relative'} margin={isPhone ? '0 -20px' : '0 auto'}>
        <ContainerCarouselViews
          ref={ref}
          justifyContent={'flex-start'}
          gap={'16px'}
          padding={isPhone ? '0 20px' : '0'}
          overflowX={'scroll'}
        >
          {subcategories.map(({ title, slug, carouselTitle, carouselBanner, metalIso }, index) => (
            <SlideBanner
              key={slug}
              index={index}
              title={carouselTitle || title}
              slug={slug}
              image={carouselBanner}
              metalIso={metalIso}
              setSlidesWidth={setSlidesWidth}
            />
          ))}
        </ContainerCarouselViews>

        {!isPhone && (
          <>
            <ButtonArrow
              orientation={EPosition.LEFT}
              onClick={handlePrevious}
              isActive={scrollLeft > 0}
              top={isMobile ? '75px' : '125px'}
              indent={'-20px'}
              backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A50 }}
            />
            <ButtonArrow
              orientation={EPosition.RIGHT}
              onClick={handleNext}
              isActive={scrollLeft < scrollWidth}
              top={isMobile ? '75px' : '125px'}
              indent={'-20px'}
              backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A50 }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export const getSliderSubcategoriesBanner = (section: SanityWidget) => {
  const props = section as unknown as SanitySliderSubcategoriesBanner;

  if (!props?.subcategoryCarousel?.subcategories) {
    return null;
  }

  return <SliderSubcategoriesBanner subcategories={props.subcategoryCarousel.subcategories} />;
};
